.login-page-container {
  position: relative;
  height: 100vh;
  overflow-y: scroll;
}

.login-left {
	position: relative;
	background-color: #2148C0;
	display: flex;
	justify-content: center;
	align-items:center;
	min-height:500px;
	overflow: hidden;
}

.left-design{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: calc(100% - 100px);
}

.qr-img{
	width: 367px; 
	height: 265px;
	z-index: 1;
}

.qr-gentext{
	color: #ffffff;
	font-size: 43px;
	margin-bottom: 30px;
}

.login-right {
  position: relative;
  margin: auto;
}

.login-form {
    padding: 20px;
}

.login-head {
	display: flex;
    flex-direction: column;
    margin-bottom: 20px;
	align-items: center;
}

.loginSubHead {
    color: #203a5c;
    font-size: 30px;
    font-weight: 700;
}

.login-body .login-form-group {
    width: 100%;
    margin-bottom: 20px;
}

.login-password-txt {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
	align-items: center;
}

.powered{
		color: #fff;
		position: absolute;
		right: 5px;
		bottom: 5px;
}

/* Curves start from here*/

.curve{
	position: absolute;
	height: 600px;
	width: 300px;
	left: -100px;
	top: 300px;
}

.curve::after{
	content: '';
	display: block;
	position: absolute;
	border-radius: 100%;
	width: 100%;
	height: 100%;
	background-color: #264eca;
}

.curve2{
	position: absolute;
	height: 450px;
	width: 200px;
	left: -50px;
	top: 360px;
}

.curve2::after{
	content: '';
	display: block;
	position: absolute;
	border-radius: 100%;
	width: 100%;
	height: 100%;
	background-color: #2148c0;
}

.curve3{
	position: absolute;
	height: 370px;
	width: 150px;
	left: -30px;
	top: 400px;
}

.curve3::after{
	content: '';
	display: block;
	position: absolute;
	border-radius: 100%;
	width: 100%;
	height: 100%;
	background-color: #264eca;
}
/*Curves end here*/


/* Media queries start here */
@media (min-width: 821px){
	.login-page-container {
		display: grid;
  		grid-template-columns: 1fr 1fr;
		overflow: hidden;
	}
	.login-form{
		min-width: 400px;

	}
}

@media (max-width: 389px){
	.qr-gentext{
		font-size: 30px;
	}
}
/* Media queries end here */