body{
	background-color: #f7f8fc;
}

.form-preview-container{
  position: relative;
  height: 100vh;
  margin-top: 100px;
}

/* left side styles */
.form-left {
	position: relative;
	background-color: #f7f8fc;
}

.form-wrapper {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
    min-height: 500px;
	max-width: 700px;
	margin: 0px 0px 20px 50px;
}

.form-header {
    display: flex;
    position: relative;
    text-align: start;
    justify-content: space-between;
    flex-direction: column;
	padding: 20px;
	background-color: #d9d9d9
}

.personal-details{
	border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.form-btn{
	text-align: center;
}


/* right side styles */
.preview-right{
	position: relative;
	background-color: #fff;
	text-align: center;
	padding: 20px 40px 20px 40px;
	max-width: 400px;
	height: fit-content;
	border-radius: 50px;
	margin-left: auto;
	margin-right: auto;
  }

.main-profile{
	display: flex;
	flex-direction: column;
	background-color: #4d4d4d;
	color: #fff;
	min-width: 300px;
	border-radius: 30px 30px 0px 0px;
}

.main-profile-top{
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px;
}

.ava-img{
	width: 86px;
	height: 104px;
	border-radius: 50%;
}

.add-info{
	background-color: #e6e6e6;
	color: #000;
	text-align: left;
	display: flex;
	flex-direction: column;
	padding: 30px 0px 30px 30px;
	border-radius: 0px 0px 30px 30px;
}

/* Media queries start here */
@media (min-width: 930px){
	.form-preview-container{
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
	.form-wrapper{
		margin: 0px 0px 50px 100px;
	}
}

/* Media queries end here */