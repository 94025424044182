.profile-wrapper{
	margin: auto;
	padding: 10px;
	max-width: 400px;
}

.main_profile{
	display: flex;
	flex-direction: column;
	background-color: #4d4d4d;
	color: #fff;
	min-width: 300px;
}

.main-profile-top{
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px;
}

.ava_img{
	width: 100px;
	height: 100px;
	border-radius: 50%;
}

.add_info{
	background-color: #e6e6e6;
	color: #000;
	text-align: left;
	display: flex;
	flex-direction: column;
	padding: 30px 0px 30px 30px;
}